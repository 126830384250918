import axios from "axios"

export const api = axios.create({
    baseURL: 'https://sv-graph-source-dev.s3.ap-south-1.amazonaws.com'
})

export const getIndexes = async () => {
    const response = await api.get('/indexes/ticker_index.json')
    return response.data
}

