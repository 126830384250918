
import React, {useEffect, useState} from "react";
import {Row, Spinner} from 'react-bootstrap';

import Axios from "axios";


export default () => {

    // const [bwidth, setBWidth] = useState(window.innerWidth);
    //
    // useEffect(() => {
    //     const updateWindowDimensions = () => {
    //         const new_width = window.innerWidth;
    //         setBWidth(new_width);
    //     };
    //     window.addEventListener("resize", updateWindowDimensions);
    //     return () => window.removeEventListener("resize", updateWindowDimensions)
    // }, []);

    const ticker = sessionStorage.getItem('ticker')
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    if(!ticker){
        return (
            <>
                <Row className="justify-content-lg-center">
                    <span> Please search to Get the Valuation for the Stocks</span>
                </Row>
            </>
        );
    }
    else {
        useEffect(() => {
            setLoading(true);
            Axios.get(`https://sv-graph-source-dev.s3.ap-south-1.amazonaws.com/data/${ticker}/${ticker}.json`)
                .then(resp =>
                    window.Bokeh.embed.embed_item(resp.data, 'myplot')).catch(setError);
            setLoading(false);
        }, []);
        useEffect(() => {
            const initialZoom = document.body.style.zoom;
            document.body.style.zoom = String(Math.round((window.innerWidth * 100) / 1920)) + '%'
            return () => {
                document.body.style.zoom = initialZoom;
            };
        }, []);

        return (
            <>
                <Row className="justify-content-lg-center">
                    <div id="myplot" style={{'fontWeight': 'bold'}}></div>
                    {loading? <Spinner animation="border" role="status" className="m-5">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : ""
                    }
                    <span>{error ? 'Requested Stock Not Found, Error : '+error: ''}</span>

                </Row>
            </>
        );
    }
};
